export const items = [

    {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        group: "admin",
        to: "/app/admin/dashboard"
    },
    {
        title: 'drawer.access.title',
        icon: "mdi-logout-variant",
        group: "admin",
        children: [
            {
                title: "drawer.access.users",
                icon: "mdi-circle-medium",
                to: "users"
            },
            {
                title: "drawer.access.roles",
                icon: "mdi-circle-medium",
                to: "roles"
            }
        ]
    },
    {
        title: 'drawer.companies.title',
        icon: "mdi-home-city-outline",
        group: "admin",
        children: [
            {
                title: "drawer.companies.list",
                icon: "mdi-circle-medium",
                to: "companies"
            }
        ]
    },
    /* {
        title: 'drawer.posts.title',
        icon: "mdi-view-dashboard",
        group: "admin",
        children: [
            {
                title: "drawer.posts.list",
                icon: "mdi-circle-medium",
                to: "posts"
            }
        ]
    }, */
    {
        title: 'drawer.games.title',
        icon: "mdi-cards-playing-heart-multiple-outline",
        group: "admin",
        children: [
            {
                title: "drawer.games.list",
                icon: "mdi-circle-medium",
                to: "games"
            },
            {
                title: "drawer.cards.list",
                icon: "mdi-circle-medium",
                to: "cards"
            },
            {
                title: "drawer.classifiers.cardTypes.list",
                icon: "mdi-circle-medium",
                to: "classifiers/card_types"
            },
            {
                title: "drawer.classifiers.gameTypes.list",
                icon: "mdi-circle-medium",
                to: "classifiers/game_types"
            }
        ]
    },
    /* {
        title: 'drawer.articles.title',
        icon: "mdi-gift",
        group: "admin",
        children: [
            {
                title: "drawer.articles.list",
                icon: "mdi-circle-medium",
                to: "articles"
            }
        ]
    }, */
    {
        title: 'drawer.configs.title',
        icon: "mdi-cog",
        group: "admin",
        children: [
            {
                title: "drawer.configs.terms",
                icon: "mdi-circle-medium",
                to: "terms"
            },
            {
                title: "drawer.configs.list",
                icon: "mdi-circle-medium",
                to: "configs"
            }
        ]
    }
];
